import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"

const SingleProject = () => {
  return (
    <div>
      <Layout>
        <div className="flex flex-col items-center justify-center py-24 px-6 lg:px-16 case_study_bg text-white lg:h-96">
          <h1 className="text-3xl lg:text-6xl font-bold text-center">
            LA-CASERA
          </h1>
          <p className="lg:w-100 text-xl font-thin pt-8"></p>
        </div>
        <div className="flex items-center justify-center py-8 px-6 lg:px-32">
          <article className=" font-thin py-16 w-full ">
            <div className="flex lg:flex-row flex-col">
              <div className="flex flex-col w-full lg:w-1/2  pr-0 lg:pr-12">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/cera.png"
                  placeholder="blurred"
                  className="my-4"
                />
                <h1 className="text-xl font-bold items-center ">
                  CAMPAIGN OBJECTIVE{" "}
                </h1>

                <div className="">
                  <p className="font-light">
                    The La Casera UTC was aimed at rewarding the brand’s
                    esteemed consumers and to further strengthen the existing
                    bond with the consumers, while also reinforcing its brand
                    positioning as a major player in the CSD category.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2  pt-8 lg:pt-0">
                <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/tlc.png"
                  placeholder="blurred"
                  className="my-4"
                  layout="constrained"
                  // aspectRatio={5 / 1}
                  style={{
                    height: 305,
                  }}
                />
                <h1 className="text-xl font-bold items-center ">CHALLENGES</h1>

                <div className="">
                  <p className="font-light">• Create Awareness </p>
                  <p className="font-light"> • Drive trial & repeat trial </p>
                  <p className="font-light"> • Increase sales</p>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col pt-12">
              <div className="flex flex-col w-full lg:w-1/2 pr-0 lg:pr-12">
                <h1 className="text-xl font-bold items-center ">CONVERSION</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/tlc.png"
                  placeholder="blurred"
                  className="my-4"
                /> */}
                <div className="">
                  <p className="font-light">
                    {" "}
                    The campaign was accompanied by nationwide TV ads, radio ads
                    and comedy video skits by social media influencers on
                    Instagram and YouTube.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full lg:w-1/2 pt-8 lg:pt-0">
                <h1 className="text-xl font-bold items-center ">RESULT</h1>
                {/* <StaticImage
                  alt="insight driven media planning"
                  src="../../images/case-studies/cera.png"
                  placeholder="blurred"
                  className="my-4"
                /> */}
                <div className="">
                  <p className="font-light">
                    <strong>Campaign deliverables:</strong>
                  </p>
                  <p className="font-light">
                    • 88% of planned Audience Reached @ an average frequency of
                    8.2 on radio.{" "}
                  </p>
                  <p className="font-light">
                    {" "}
                    • 58% of Planned Audience Reached on TV.{" "}
                  </p>
                  <p className="font-light">
                    {" "}
                    • 310,835 Audience was reached through skits.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    </div>
  )
}

export default SingleProject
